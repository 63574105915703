import React, { useEffect, useState } from "react";
import CustomButtonOval from "../../components/form/CustomButtonOval";
import CustomSearchInput from "../../components/form/CustomSearchInput";
import { clientsResource } from "../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";
import ModalClients from "./ModalClients";
import AddUpdClientModal from "./AddUpdClientModal";
import Columns from "./Columns";
import DataTableElement from "../../components/elements/DataTableElement";
import FiltersPagination from "../../components/elements/FiltersPagination";

const Clients = () => {
    const [modalConfirm, setModalConfirm] = useState({ show: false, title: 'Delete client', loadig: false, textLoad: 'Processing' })
    const [filters, setFilters] = useState({ perPage: 10, page: 1, search: '' });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading clients...' });
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState({ id: null });

    useEffect(() => {
        const clients = async () => {
            setTableLoad({ ...tableLoad, show: true })
            const response = await clientsResource('get', { params: filters });
            setClients(response.companies);
            setMeta(response.meta);
            setTableLoad({ ...tableLoad, show: false })
        };
        clients();
    }, [filters]);


    const [modal, setModal] = useState({ show: false });

    const openModalAdd = (client = {}) => {
        setClient(client)
        setModal({ ...modal, show: true, title: client.id ? "Edit Client" : 'Add Client' })
    };

    

    const deleteCompany = async () => {
        setModalConfirm({ ...modalConfirm, loading: true })
        const response = await clientsResource('del', {}, modalConfirm.id);
        if (response.status) {
            setFilters({ ...filters })
        }
        setModalConfirm({ ...modalConfirm, loading: false, show: false, id: null })
    }
    
    const cols = Columns(setModalConfirm, modalConfirm, openModalAdd)
    return (
        <>
            <div className="p-8">
                <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-xl font-bold">Clients</h1>
                    </div>
                    <div>
                        <div className="flex flex-wrap items-center gap-4 mb-4">
                            <div className="ml-auto">
                                <CustomButtonOval onClick={() => openModalAdd({ id: null })}>
                                    + Add New Client
                                </CustomButtonOval>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <FiltersPagination filters={filters} setFilters={setFilters} meta={meta} showFilters={true}>
                        <DataTableElement columns={cols} tableLoad={tableLoad} items={clients} />
                    </FiltersPagination>
                </div>
                <AddUpdClientModal clientItem={client} modal={modal} setModal={setModal} filters={filters} setFilters={setFilters} />
                <ConfirmDeleteModal modal={modalConfirm} setModal={setModalConfirm} action={deleteCompany} />
            </div>
        </>
    );
}

export default Clients;
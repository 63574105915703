import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/login/Login';
import MiddlewareAuth from './components/middleware/MiddlewareAuth';
import Loading from './components/main/Loading';
import Toast from './components/main/Toast';
import Home from './pages/home/Home';
import Team from './pages/users/Team';
import AddUpdTeam from './pages/users/AddUpdTeam';
import { ToastContainer } from 'react-toastify';
import ForgotPassword from './pages/login/ForgotPassw';
import AsignPassword from './pages/login/AsignPassword';
import SalesAccounting from './pages/payments/accounting/SalesAccounting';
import Accounting from './pages/payments/accounting/Accounting';
import Clients from "./pages/clients/Clients";
import Suscription from "./pages/suscriptions/Suscription";
import PaymentDetail from './pages/payments/accounting/PaymentDetail';

const App = () => {
  return (
    <>
      <Toast />
      <ToastContainer />
      <Loading />
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<AsignPassword />} />
          <Route path="/confirm-account/:token" element={<AsignPassword />} />

          <Route
            path="/"
            element={
              <MiddlewareAuth>
                <Home />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/team"
            element={
              <MiddlewareAuth>
                <Team />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/team-upadd/:id?"
            element={
              <MiddlewareAuth>
                <AddUpdTeam />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/subscriptions"
            element={
              <MiddlewareAuth>
                <Suscription />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/sales-accounting"
            element={
              <MiddlewareAuth>
                <SalesAccounting />
              </MiddlewareAuth>
            }
          />
          <Route
            path='/accounting'
            element={
              <MiddlewareAuth>
                <Accounting />
              </MiddlewareAuth>
            }
          />
          <Route
            path='/payment-details/:id'
            element={
              <MiddlewareAuth>
                <PaymentDetail />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/clients"
            element={
              <MiddlewareAuth>
                <Clients />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/support"
            element={
              <MiddlewareAuth>
                <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
                  <div className="flex items-center space-x-2">
                    <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12"></div>
                    <span className="text-2xl font-semibold">
                      Construction...
                    </span>
                  </div>
                </div>
              </MiddlewareAuth>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;

import React from 'react';
import { clientsResource } from '../../helpers/configRoutes/backend_helper';

const Columns = (setModalConfirm,modalConfirm,openModalAdd) => {
    

    let columns = [
        {
            id: 'id',
            name: 'Company / Name',
            selector: row => row.id,
        },
        {
            id: 'name',
            name: 'Company / Name',
            selector: row => row.name,
        },
        {
            id: 'address',
            name: 'Address',
            selector: row => row.address,
        },
        {
            id: 'created_at',
            name: 'Register Date',
            selector: row => row.created_at,
        },
        {
            id: 'plan_name',
            name: 'Subscription',
            selector: row => row.plan_name,
        },

        {
            id: 'status',
            name: 'Status',
            button: true,
            //Aqui van botones como eliminar o editar
            cell: row => (
                <span
                    className={`px-2 py-1 rounded-full text-sm ${row.status_name === "Active"
                        ? "bg-green-200 text-green-700"
                        : row.status_name === "Trial"
                            ? "bg-purple-200 text-purple-700"
                            : "bg-red-200 text-red-700"
                        }`}
                >
                    {row.status_name}
                </span>
            ),
        },
        {
            id: 'actions',
            name: '',
            button: true,
            //Aqui van botones como eliminar o editar
            cell: client => (
                <div className="flex items-center space-x-2">
                    {/* Botón de edición */}
                    <button onClick={() => openModalAdd(client)} className="flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100">
                        <i className="fas fa-pen text-gray-500"></i>
                    </button>
                    {/* Botón de eliminar */}
                    <button onClick={() => setModalConfirm({ ...modalConfirm, show: true, id: client.id })} className={`flex items-center justify-center px-4 py-2 ${!client.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!client.can?.delete}>
                        <i className={`fas fa-trash ${!client.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                    </button>
                </div>
            ),
        }


    ];
    return columns;
}

export default Columns;

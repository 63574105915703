import React, { useEffect, useState } from "react";
import CustomButtonOval from "../form/CustomButtonOval";

const BaseModalAnimated = ({ modal, setModal, children, ...props }) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (modal.show) {
            setShowModal(true);
        }
    }, [modal.show]);

    const closeModal = () => {
        setShowModal({...modal, show: false});
        setTimeout(() => {
            setModal({ show: false, id: null });
        }, 300); // Duración de la animación
    };

    return (
        <>
            {modal.show ? (
                <>
                    <div className={`fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none ${showModal ? "opacity-100 translate-y-0 scale-100" : "opacity-0 translate-y-4 scale-95"} transition-all duration-300 ease-in-out`}>
                        <div className="relative w-[80%] sm:w-[80%] md:w-[50%] lg:w-[54%] max-w-2xl mx-auto my-6">
                            <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none focus:outline-none bg-white">
                                {/* Encabezado del modal con botón de cierre */}
                                <div className={`flex items-start justify-between p-2 ${modal.user ? 'border-b border-solid border-gray-200':''} rounded-t`}>
                                    <h3 className={modal.user ? 'text-lgxl':"text-xl mt-10 ml-10"}>{modal.title}</h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={closeModal}
                                    >
                                        <span className="text-black opacity-50 hover:opacity-100">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z" fill="#FCFCFC" stroke="#888888" strokeWidth="0.4" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.9998 15.175L19.0874 12.0875C19.3152 11.8597 19.6845 11.8597 19.9123 12.0875C20.1401 12.3153 20.1401 12.6846 19.9123 12.9124L16.8248 16L19.9123 19.0875C20.1401 19.3153 20.1401 19.6846 19.9123 19.9124C19.6845 20.1402 19.3152 20.1402 19.0874 19.9124L15.9998 16.8249L12.9123 19.9124C12.6845 20.1402 12.3152 20.1402 12.0874 19.9124C11.8596 19.6846 11.8596 19.3153 12.0874 19.0875L15.1749 16L12.0874 12.9124C11.8596 12.6846 11.8596 12.3153 12.0874 12.0875C12.3152 11.8597 12.6845 11.8597 12.9123 12.0875L15.9998 15.175Z" fill="#888888" />
                                                <mask id="mask0_231_8688" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="11" y="11" width="10" height="10">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.9998 15.175L19.0874 12.0875C19.3152 11.8597 19.6845 11.8597 19.9123 12.0875C20.1401 12.3153 20.1401 12.6846 19.9123 12.9124L16.8248 16L19.9123 19.0875C20.1401 19.3153 20.1401 19.6846 19.9123 19.9124C19.6845 20.1402 19.3152 20.1402 19.0874 19.9124L15.9998 16.8249L12.9123 19.9124C12.6845 20.1402 12.3152 20.1402 12.0874 19.9124C11.8596 19.6846 11.8596 19.3153 12.0874 19.0875L15.1749 16L12.0874 12.9124C11.8596 12.6846 11.8596 12.3153 12.0874 12.0875C12.3152 11.8597 12.6845 11.8597 12.9123 12.0875L15.9998 15.175Z" fill="white" />
                                                </mask>
                                                <g mask="url(#mask0_231_8688)">
                                                </g>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div className="p-1">
                                {/* Cuerpo del modal */}
                                {children}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Fondo oscuro con opacidad aumentada */}
                    <div className="fixed inset-0 z-40 bg-black opacity-50 transition-opacity duration-300"></div>
                </>
            ) : null}
        </>
    );
};

export default BaseModalAnimated;

import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButton from "../../components/form/CustomButton";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getPeriods, plansResource } from "../../helpers/configRoutes/backend_helper";
import CustomInput from "../../components/form/CustomInput";
import { showFormErrors } from "../../helpers/showFormErrors";
import CustomSelect from "../../components/form/CustomSelect";

const AddUpdPlansModal = ({ modal, setModal, planItem, setFilters, filters }) => {

    const navigate = useNavigate()
    const save = () => {
        console.log('Guardar datos o editar')
    }
    const [loading, setLoading] = useState(false)
    const baseitem = {
        id: null,
        name: '',
        price: '',
        active: false,
        day_trial: '',
        trucks: '',
        users: '',
        period_id:'',
        taxes:0
    }

    const validation = useFormik({
        initialValues: planItem,
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            price: Yup.string().required('Price is required'),
            day_trial: Yup.string().required('Trial period is required'),
            trucks: Yup.string().required('Trucks are required'),
            taxes: Yup.string().required('Trucks are required'),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await plansResource(values.id ? 'put' : 'post', values, values.id);
            if (response.status) {
                setLoading(false)
                setFilters({ ...filters, search: filters.search })
                setModal({ ...modal, show: false })
            } else {
                setErrors(response.errors); // Establecer errores en caso de fallo
            }
            setLoading(false)
        }
    });

    const [paymentPeriods,setPaymentPeriods]=useState([])

    useEffect(() => {
        if (planItem.id) {
            validation.setValues(planItem); // Actualizar los valores del formulario cuando `client` cambia
        } else {
            validation.resetForm({ values: baseitem }); // Resetea el formulario a los valores por defecto si no hay cliente
        }
    }, [modal]);

    useEffect(() => {
        const getPaymentPeriods = async() =>{
            const response = await getPeriods()
            setPaymentPeriods(response.periods)
        }
        getPaymentPeriods()
    }, []);

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation)
                    return false;
                }}>
                    <div className="grid grid-cols-2 gap-4">
                        <CustomInput
                            label="Subscription Name"
                            type="text"
                            name="name"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.name}
                            value={validation?.values?.name}
                            handleChange={validation.handleChange}
                        />
                        <CustomSelect
                            label="Payment"
                            options={paymentPeriods}
                            name="period_id"
                            labelClassName={'text-sm text-[#ADADAD] whitespace-nowrap'}
                            error={validation?.errors?.period_id}
                            value={validation?.values?.period_id}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Price"
                            type="number"
                            name="price"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.price}
                            value={validation?.values?.price}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Users"
                            type="number"
                            name="users"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.users}
                            value={validation?.values?.users}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Trucks"
                            type="number"
                            name="trucks"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.trucks}
                            value={validation?.values?.trucks}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Days of Trial"
                            type="number"
                            name="day_trial"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.day_trial}
                            value={validation?.values?.day_trial}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Taxes %"
                            type="number"
                            name="taxes"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.taxes}
                            value={validation?.values?.taxes}
                            handleChange={validation.handleChange}
                        />

                    </div>



                    <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                        <CustomButton
                            loading={loading}
                            textLoad={'Saving'}
                            type={"success"}
                            onClick={save}
                            className="w-full lg:w-[30%]"
                        >
                            {validation.values?.id ? 'Save' : 'Add new'}
                        </CustomButton>
                    </div>
                </form>
            </BaseModalAnimated >
        </>
    )

}
export default AddUpdPlansModal;
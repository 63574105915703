import React, { useEffect, useState } from "react";
import { getPrefixes, usersResource } from "../../helpers/configRoutes/backend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../components/form/CustomInput";
import CustomSelect from "../../components/form/CustomSelect";
import { showFormErrors } from "../../helpers/showFormErrors";
import FileInput from "../../components/form/FileInput";
import ModulesPermissions from "./ModulesPermissions";
import CustomButton from "../../components/form/CustomButton";
import { validatePhones } from "../../helpers/forms/Validation";

const AddUpdTeam = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Obtener ID si estamos editando
    const [prefixes, setPrefixes] = useState([]);
    const [modal, setModal] = useState({ show: false,loading:true, textLoad:'Saving',title:'Select Permmissions',user:true});
    const [loading, setLoading] = useState(false);
    // Obtener usuario y asignar valores al formulario
    useEffect(() => {
        const getUser = async () => {
            const response = await usersResource('show', {}, id);
            if (response.status) {
                Object.keys(response.user).forEach(field => {
                    validation.setFieldValue(field, response.user[field], false); // Setear valores en el formik sin disparar validación
                });
            }
        }
        if (id) {
            getUser();
        }
    }, [id]);

    // Obtener catálogo de prefijos
    useEffect(() => {
        const getPrefixesCatalog = async () => {
            const response = await getPrefixes();
            if (response.status) {
                setPrefixes(response.prefixes);
            }
        }
        getPrefixesCatalog();
    }, []);

    // Formik para manejo del formulario y validaciones
    const validation = useFormik({
        initialValues: {
            id: null,
            active: true,
            email: '',
            first_name: '',
            last_name: '',
            role: '',
            phone: '',
            prefix_id: '',
            company_id: '',
            imageProfile: '',
            modelsPermissions:[]
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email').required('Email is required'),
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            role: Yup.string().required('Role is required'),
            phone: validatePhones(true),
            prefix_id: Yup.string().required('Prefix is required'),
            imageProfile: '',
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await usersResource(values.id ? 'put' : 'post', values, values.id);
            if (response.status) {
                navigate('/team'); // Navegar al equipo si la respuesta es exitosa
            } else {
                setErrors(response.errors); // Establecer errores en caso de fallo
            }
            setLoading(false)
        }
    });

    return (
        <>
            <div className="p-8 bg-gray-100 min-h-screen">
                {/* Header */}
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold">{id ? 'Update ' : 'Add New '}Member</h1>
                </div>

                {/* Form Container */}
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation)
                    return false;
                }}>
                    <div className="bg-white p-6 rounded-lg shadow-lg mx-auto">
                        {/* Profile Picture */}
                        <div className="flex justify-center items-center mb-6">
                            <div className="relative flex flex-col items-center">
                                <img
                                    className="w-24 h-24 rounded-full mb-3"
                                    src={validation.values.imageProfile || 'https://placehold.co/40x40'}
                                    alt="Profile"
                                />
                                {/* Input de archivos oculto */}
                                <FileInput
                                    format={'img'}
                                    name={'imageProfile'}
                                    validation={validation}
                                />
                            </div>
                        </div>
                        {/* Form Fields */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {/* First Name */}
                            <div>
                                <CustomInput
                                    label="First Name"
                                    type="text"
                                    name="first_name"
                                    labelClassName={'text-base'}
                                    error={validation?.errors?.first_name}
                                    value={validation?.values?.first_name}
                                    handleChange={validation.handleChange}
                                    
                                />
                            </div>

                            {/* Last Name */}
                            <div>
                                <CustomInput
                                    label="Last Name"
                                    type="text"
                                    name="last_name"
                                    labelClassName={'text-base'}
                                    error={validation?.errors?.last_name}
                                    value={validation?.values?.last_name}
                                    handleChange={validation.handleChange}
                                />
                            </div>

                            {/* Email */}
                            <div>
                                <CustomInput
                                    label="Your Email"
                                    type="text"
                                    name="email"
                                    labelClassName={'text-base'}
                                    error={validation?.errors?.email}
                                    value={validation?.values?.email}
                                    handleChange={validation.handleChange}
                                />
                            </div>

                            {/* Role */}
                            <div>
                                <CustomInput
                                    label="Role"
                                    type="text"
                                    name="role"
                                    labelClassName={'text-base'}
                                    error={validation?.errors?.role}
                                    value={validation?.values?.role}
                                    handleChange={validation.handleChange}
                                />
                            </div>

                            {/* Prefixes */}
                            <div>
                                <CustomSelect
                                    label="Phone Prefix"
                                    options={prefixes}
                                    name="prefix_id"
                                    labelClassName={'text-base'}
                                    error={validation?.errors?.prefix_id}
                                    value={validation?.values?.prefix_id}
                                    handleChange={validation.handleChange}
                                />
                            </div>

                            {/* Phone Number */}
                            <div>
                                <CustomInput
                                    label="Phone Number"
                                    type="text"
                                    name="phone"
                                    labelClassName={'text-base'}
                                    error={validation?.errors?.phone}
                                    value={validation?.values?.phone}
                                    handleChange={validation.handleChange}
                                />
                            </div>

                            {/* Status */}
                            <div>
                                <CustomSelect
                                    label="Status"
                                    options={[{ id: 1, name: 'Active' }, { id: 0, name: 'Suspended' }]}
                                    name="active"
                                    disabled={validation.values.id ? !validation.values?.can?.disable:false}
                                    labelClassName={'text-base'}
                                    error={validation?.errors?.active}
                                    value={validation?.values?.active}
                                    handleChange={validation.handleChange}
                                />
                            </div>
                        </div>

                        {/* Permissions */}
                        <div className="mt-4">
                            <a href="#" onClick={() => setModal({ show: true, title: 'Select Permmissions' })} className="text-blue-500 underline">Edit Permissions</a>
                        </div>

                        {/* Submit Button */}
                        <div className="flex mt-6 justify-center w-full">
                            <CustomButton loading={loading} textLoad={'Processing'} type="submit" className="w-1/4 bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600">
                                {id ? 'Save Changes' : 'Add User'}
                            </CustomButton>
                        </div>
                    </div>
                </form>
            </div>
            {modal.show &&
                <ModulesPermissions modal={modal} setModal={setModal} validation={validation}/>
            }
            
        </>
    )
}

export default AddUpdTeam;
